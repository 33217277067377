/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// react-router components
// import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import logout from "api/auth/logout";
import editUserLanguage from "api/users/editUserLanguage";
import addLog from "api/logs/addLog";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Tooltip from "@mui/material/Tooltip";
import LanguageSelector from "components/LanguageSelector";

// Material Dashboard 2 PRO React example components
// import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarDesktopMenu,
  // navbarIconButton,
  // navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  setUser,
  setClient,
  useMaterialUIController,
  // setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";

function DashboardNavbar({ absolute, light, isMini }) {
  // const [navbarType, setNavbarType] = useState();
  const [transparentNavbar, setTransparentNavbar] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { fixedNavbar, darkMode, user, customers, miniSidenav } = controller;
  // const [languageLoading, setLanguageLoading] = useState(false);
  // const route = useLocation().pathname.split("/").slice(1);
  // const navigate = useNavigate();
  const { t } = useTranslation();

  // useEffect(() => {
  // }, []);

  useEffect(() => {
    // Setting the navbar type
    // if (fixedNavbar) {
    //   setNavbarType("sticky");
    // } else {
    //   setNavbarType("static");
    // }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar((fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    if (user && user.role && user.role === "user" && customers && customers.length > 0) {
      const obj = {
        cookies: [],
        passwords: [],
      };

      customers.forEach((item) => {
        if (item.cookies.length > 0) {
          item.cookies.forEach((cookie) => obj.cookies.push(cookie));
        }

        if (item.passwords.length > 0) {
          item.passwords.forEach((password) => obj.passwords.push(password));
        }
      });

      // setClient(dispatch, obj);
    }
  }, [user, customers]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const reload = () => {
    setTimeout(() => {
      // setLanguageLoading(true);
      window.location.reload();
    }, 1000);
  };

  const selectLanguage = (language) => {
    editUserLanguage({ language }, user.id)
      .then(() => {
        // setUser(dispatch, res);
        reload();
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("errore durante il cambio lingua", err);
      });
  };

  const performLogout = () => {
    logout(null, user)
      .then((res) => {
        // eslint-disable-next-line
        console.log("logout success", res);
        const logToSend = {
          email: user && user.email ? user.email : "",
          type: "Logout",
          date: new Date(),
        };
        addLog(logToSend).then(() => {
          setUser(dispatch, null);
          window.location.href = "/login";
        });
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  };

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      // position={absolute ? "absolute" : navbarType}
      position="sticky"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        <div style={{ display: "flex", color: "var(--title-color)" }}>
          {/* eslint-disable-next-line */}
          {user && user.id ? (
            <div style={{ marginRight: "40px", display: "flex", alignItems: "center" }}>
              <LanguageSelector onSelect={selectLanguage} user={user} />
            </div>
          ) : null}
          {user && (
            <div style={{ minWidth: "150px", display: "flex", alignItems: "center" }}>
              <Link to="/user">
                <IconButton>
                  <Icon fontSize="medium" style={{ cursor: "pointer" }}>
                    account_circle
                  </Icon>
                </IconButton>
              </Link>
              <MDTypography variant="button" sx={{ fontWeight: 600, color: "#6e7074" }}>
                {user.name}
              </MDTypography>
            </div>
          )}
          <IconButton
            sx={navbarDesktopMenu}
            style={{ display: "flex" }}
            onClick={performLogout}
            size="small"
            disableRipple
          >
            <Tooltip title="Logout" placement="top" style={{ marginLeft: 20 }}>
              <Icon fontSize="medium" sx={iconsStyle}>
                logout
              </Icon>
            </Tooltip>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
