const codes = {
  en: {
    translation: {
      codes: "Codes",
      date: "Date",
      status: "Status",
      services: "Services",
      code: "Code",
      add_code: "Add code",
      note: "Note",
      code_numbers: "Number of codes to create",
      add_code: "Add code",
      codes_added: "Codes added",
      no_code_yet: "No codes yet",
      code_redeemed: "Code redeemed",
      redeemed_by: "Redeemed by",
      when: "When",
      with_ip: "With IP",
      credentials_limit: "Credentials limit",
      duration: "duration",
      months: "months",
      select_service: "Select a service package",
    },
  },
  it: {
    translation: {
      codes: "Codici",
      date: "Data",
      status: "Stato",
      services: "Servizi",
      code: "Codice",
      add_code: "Aggiungi codice",
      note: "Note",
      code_numbers: "Numero di codici da generare",
      add_code: "Aggiungi codice",
      codes_added: "Codici aggiunti",
      no_code_yet: "Non ci sono ancora codici",
      code_redeemed: "Codice riscattato",
      redeemed_by: "Riscattato da",
      when: "Quando",
      with_ip: "Con IP",
      credentials_limit: "Limite credenziali",
      duration: "durata",
      months: "mesi",
      select_service: "Seleziona un pacchetto di servizi",
    },
  },
};

export default codes;
