// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import addCode from "api/codes/addCode";
import getCodes from "api/codes/getCodes";
import getServicesPackages from "api/servicepackages/getServicesPackages";
import MDInput from "components/MDInput";
import SweetAlert from "react-bootstrap-sweetalert";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "context";
import Pagination from "layouts/dashboards/stealers/pagination";

function Codes() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [note, setNote] = useState("");
  const [codes, setCodes] = useState([]);
  const [codesNumber, setCodesNumber] = useState(1);
  const [selectedPackage, setSelectedPackage] = useState(""); // Changed to single string
  const [duration, setDuration] = useState(1);
  const [credentialsLimit, setCredentialsLimit] = useState(5);
  const [addingCode, setAddingCode] = useState(false);
  const [addedPopup, setAddedPopup] = useState(false);
  const [newCreated, setNewCreated] = useState([]);
  const [redeemInfo, setRedeemInfo] = useState(null);
  const [serverError, setServerError] = useState({
    ransomwaresCounters: false,
    ransomwaresTable: false,
  });
  const [servicesPackages, setServicesPackages] = useState([]);
  const { t } = useTranslation();

  function setRequest(res) {
    if (res.results && res.results.length > 0) {
      setCodes(res.results);
    }
    if (res && res.length > 0) {
      setCodes(res);
    } else if (res.length === 0 || (res.results && res.results.length === 0)) {
      setCodes([]);
    }
  }

  function refreshCodes() {
    getCodes(page, limit)
      .then((res) => {
        setPages(res.totalPages);
        setRequest(res);
      })
      .catch((err) => {
        setServerError({ ...serverError, ransomwaresTable: true });
        // eslint-disable-next-line no-console
        console.log("error", err);
      });
  }

  function refreshServicesPackages() {
    getServicesPackages(page, limit)
      .then((res) => {
        setServicesPackages(res);
      })
      .catch((err) => {
        setServerError({ ...serverError });
        // eslint-disable-next-line no-console
        console.log("error", err);
      });
  }

  useEffect(() => {
    refreshServicesPackages();
  }, []);

  useEffect(() => {
    refreshCodes();
  }, [page, limit]);

  function getInput(type, editFunction, defaultValue) {
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        defaultValue={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "20px", marginTop: "20px" }}
      />
    );
  }

  function getSelect(
    options,
    editFunction,
    defaultValue,
    label = t("codes.duration"),
    endingLabel = t("codes.months")
  ) {
    return (
      <div style={{ marginTop: "20px", fontSize: "18px" }}>
        {label}:{" "}
        <select
          onChange={(e) => {
            editFunction(e.target.value);
          }}
          defaultValue={defaultValue}
          className="selectDefault"
          style={{
            width: "initial",
            padding: "0.35rem",
            borderRadius: "0.375rem",
            borderColor: "#d2d6da",
          }}
        >
          {options.map((el, index) => (
            <option value={el} key={`option-role-${index.toString()}`}>
              {el}
            </option>
          ))}
        </select>{" "}
        {endingLabel}
      </div>
    );
  }

  function getServices(services) {
    return services.join(", ");
  }

  function getPackageSelection() {
    const handlePackageSelect = (e) => {
      const packageCode = e.target.value;
      setSelectedPackage(packageCode);
    };

    return (
      <div className="services-selection-container" style={{ marginBottom: "15px" }}>
        <select
          onChange={handlePackageSelect}
          className="headerCustomersDropdown fixed-width-select"
          value={selectedPackage}
          style={{
            padding: "0.35rem",
            borderRadius: "0.375rem",
            borderColor: "#d2d6da",
          }}
        >
          <option value="" disabled>
            {t("codes.select_service")}
          </option>
          {servicesPackages.map((pkg) => (
            <option key={pkg.id} value={pkg.id}>
              {pkg.name}
            </option>
          ))}
        </select>
      </div>
    );
  }

  function getSelectedPackageDisplay() {
    if (!selectedPackage) {
      return null;
    }

    const packageInfo = servicesPackages.find((pkg) => pkg.code === selectedPackage);

    if (!packageInfo) {
      return null;
    }

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <button
          className="service-selection-btn"
          style={{
            position: "relative",
            paddingRight: "30px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          {packageInfo.name}
          <Icon
            style={{
              position: "absolute",
              right: "8px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedPackage("");
            }}
          >
            close
          </Icon>
        </button>
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 className="page-title" style={{ marginBottom: "50px" }}>
        {t("codes.codes")}
      </h2>
      <div style={{ marginBottom: "50px" }}>
        <IconButton
          onClick={() => {
            setAddingCode(true);
          }}
          className="default-button"
          size="small"
          disableRipple
        >
          {t("codes.add_code")}
        </IconButton>
      </div>
      {addingCode ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("codes.add_code")}
          showCancel
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{
            boxShadow: "none",
          }}
          cancelBtnText={t("close")}
          onCancel={() => {
            setAddingCode(false);
          }}
          onConfirm={() => {
            if (selectedPackage) {
              console.log("selectedPackage", selectedPackage);
              addCode({
                servicePackage: selectedPackage, // Changed to single value
                // duration,
                note: note || "",
                codeNumbers: Number(codesNumber),
                // credentialsLimit,
              })
                .then((res) => {
                  setAddedPopup(true);
                  setNewCreated(res);
                  refreshCodes();
                })
                .catch((err) => {
                  // eslint-disable-next-line
                  console.log("err", err);
                });
              setAddingCode(false);
            }
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {/* servizi */}
          <div style={{ marginBottom: "10px", fontSize: "18px" }}>{t("codes.services")}:</div>
          {getPackageSelection()}
          {/* servizi selezionati */}
          {getSelectedPackageDisplay()}
          {/* durata */}
          {/* {getSelect([1, 3, 6, 12, 24], setDuration, duration)} */}
          {/* numero di credenziali disponibili */}
          {/* {getSelect(
            [5, 10, 15, 20],
            setCredentialsLimit,
            credentialsLimit,
            t("codes.credentials_limit"),
            " "
          )} */}
          {/* note */}
          {getInput(t("codes.note"), setNote, note)}
          {/* numero codici */}
          {getInput(t("codes.code_numbers"), setCodesNumber, codesNumber)}
        </SweetAlert>
      ) : null}
      {addedPopup && newCreated?.length > 0 ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("codes.codes_added")}
          onConfirm={() => {
            setAddedPopup(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          <div className="code-created-list">
            {newCreated.map((code) => (
              <div key={`code-created-${code}`} className="code-created-in-list">
                {code}
              </div>
            ))}
          </div>
        </SweetAlert>
      ) : null}
      {redeemInfo && redeemInfo.user ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("codes.code_redeemed")}
          onConfirm={() => {
            setRedeemInfo(null);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          <div style={{ fontSize: "18px" }}>
            {t("codes.redeemed_by")}:{" "}
            <span style={{ fontWeight: "bold" }}>{redeemInfo?.user?.name}</span>
          </div>
          <div style={{ fontSize: "18px" }}>
            {t("codes.when")}:{" "}
            <span style={{ fontWeight: "bold" }}>
              {redeemInfo?.dateClaim ? moment(redeemInfo.dateClaim).format("YYYY-MM-DD HH:mm") : ""}
            </span>
          </div>
          <div style={{ fontSize: "18px" }}>
            {t("codes.with_ip")}:{" "}
            <span style={{ fontWeight: "bold" }}>{redeemInfo?.redeemedByIp}</span>
          </div>
        </SweetAlert>
      ) : null}
      {codes && codes.length > 0 ? (
        <div className="table_users_list">
          <table className="table-content" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>{t("codes.date")}</th>
                <th style={{ textAlign: "center" }}>{t("codes.code")}</th>
                <th style={{ textAlign: "center" }}>{t("codes.status")}</th>
                <th style={{ textAlign: "center" }}>{t("codes.services")}</th>
              </tr>
            </thead>
            <tbody>
              {codes.map((code, index) => (
                <tr key={index.toString()} style={{ textAlign: "center" }}>
                  <td>{code.date ? moment(code.date).format("DD/MM/YYYY") : null}</td>
                  <td>{code.code || ""}</td>
                  <td>
                    {code.status || ""}
                    {code.status === "redeemed" ? (
                      <IconButton
                        onClick={() => {
                          setRedeemInfo(code);
                        }}
                        style={{ color: "green" }}
                        size="small"
                        disableRipple
                      >
                        <Icon fontSize="medium">check</Icon>
                      </IconButton>
                    ) : null}
                  </td>
                  <td>
                    {code.services && code.services.length > 0 ? getServices(code.services) : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : (
        <div>{t("codes.no_code_yet")}</div>
      )}
    </DashboardLayout>
  );
}

export default Codes;
